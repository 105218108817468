import { PaletteOptions } from '@mui/material/styles';

interface ICustomPalette extends PaletteOptions {
  grey1: string;
  grey2: string;
  grey3: string;
  grey4: string;
  grey5: string;
  black: string;
  white: string;
  link: string;
  border: {
    primary: string;
    secondary: string;
    main: string;
  };
  icon: string;
  textField: string;
  sideBar: string;
  sideBarMenu: string;
  sideBarMenuHover: string;
  page: string;
  primary: {
    contrastText: string;
    dark: string;
    main: string;
    light: string;
  };
  success: {
    contrastText: string;
    main: string;
    secondary: string;
  };
  diagrams: {
    background: string;
    blue: string;
    main: string;
    purple: string;
    red: string;
  };
  requestFields: {
    query: string;
    kind: string;
    dataPartition: string;
    aggregation: string;
    limit: string;
    offset: string;
    sort: string;
    status: string;
    scope: string;
    latest: string;
  };
}

const white = '#ffffff';

export const palette: ICustomPalette = {
  black: '#000',
  white: white,
  grey1: '#FAFBFC',
  grey2: '#E0E4E9',
  grey3: '#F3F3F4',
  grey4: '#6A717A',
  grey5: '#30373E',
  link: '#0165A6',
  primary: {
    contrastText: white,
    dark: '#043D6D',
    main: '#0165A6',
    light: '#3383B7',
  },
  secondary: {
    contrastText: white,
    dark: '#950000',
    main: '#AD0000',
    light: 'rgba(149, 0, 0, .9)',
  },
  success: {
    contrastText: white,
    main: '#388E3C',
    secondary: '#266d28',
  },
  info: {
    contrastText: white,
    main: '#6A717A',
  },
  warning: {
    contrastText: white,
    main: '#FBC02D',
  },
  error: {
    contrastText: white,
    main: '#AD0000',
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.6)',
  },
  background: {
    // gradient: 'linear-gradient(90deg, #043D6D 0%, #0165A6 100%)',
    paper: white,
    // default: '#FAFBFC',
  },
  border: {
    primary: '#0165A6',
    secondary: '#AD0000',
    main: '#E0E4E9',
  },
  diagrams: {
    background: '#0474bb',
    blue: '#04a0ff',
    main: '#7da1b1',
    purple: '#6d2c9e',
    red: '#D12B23',
  },
  page: '#FAFBFC',
  icon: '#FAFBFC',
  divider: '#B2BBC1',
  textField: '#E0E4E9',
  sideBar: '#141B2D',
  sideBarMenu: '#17212C',
  sideBarMenuHover: '#283848',
  requestFields: {
    query: '#74bbe8',
    kind: '#a07cfc',
    dataPartition: '#FBC02D',
    aggregation: '#ec87baff',
    limit: '#D12B23',
    offset: '#0bed00',
    sort: '#00d3a2',
    status: '#00f6ff',
    scope: '#ffa100',
    latest: '#f262fc',
  },
};
