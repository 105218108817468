export interface Timefragment {
  hour: number;
  minute: number;
  seconds: number;
  display: string;
}

export enum PLATFORM_STATE {
  ON = 'ON',
  OFF = 'OFF',
  ERROR = 'ERROR',
  CHECKING = 'CHECKING',
  PENDING = 'PENDING',
  TURNING_ON = 'TURNING_ON',
}

export enum PLATFORM_MODAL_STATE {
  ON = 'On',
  OFF = 'Off',
  UNSET = 'Unset',
}

interface IPlatformState {
  PlatformState: PLATFORM_STATE;
  TTL: number;
  Timestamp?: Date;
  tooltipMessage: string;
  isLoading: boolean;
  serviceStatuses: Array<IServiceData>;
}

enum EServiceType {
  WEB = 'Web',
  Storage = 'Storage',
  Workflow = 'Workflow',
}

enum EService {
  SEARCH = 'Search',
  STORAGE = 'Storage',
  ENTITLEMENTS = 'Entitlements',
  INDEXER = 'Indexer',
  REGISTER = 'Register',
  PARTITION = 'Partition',
  SCHEMA = 'Schema',
  NOTIFICATION = 'Notification',
  WELLBORE_DDMS = 'Wellbore DMS',
  FILE = 'File',
  DATASET = 'Dataset',
  POLICY = 'Policy',
  AIRFLOW = 'Airflow Cluster',
  WELLDELIVERY = 'Well Delivery',
  UNIT = 'Unit',
  LEGAL = 'Legal',
}

interface IService {
  name: EService;
  type: EServiceType;
  displayName: string;
  readyUri: string;
  isReady: boolean | undefined;
  goodService: React.ReactNode;
  badService: React.ReactNode;
  unknownService: React.ReactNode;
  isLoading: boolean;
  shouldDisplay?: boolean;
  sequence: number;
}

export interface IServiceData {
  name: string;
  uri: string;
  isHidden?: boolean;
  status: number;
}

export type { IPlatformState as IPlatformState };
export { EServiceType, EService as EServiceName };
export type { IService as IServices };
