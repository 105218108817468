import {MapContainer, Marker, Popup, TileLayer} from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import {MapContainerProps, Pin} from "../../Utils/mapViewer";
import "leaflet-loading"
import * as S from './MapViewerPage.style';
import {Link} from 'react-router-dom';
import Card from '@mui/material/Card';
import {useEffect, useRef} from "react";


export default function LeafletMapContainer({geoPoints, coordinates}: MapContainerProps) {
  const map = useRef(null)
  useEffect(() => {
    if (map.current !== null && coordinates[0] !== 0 && coordinates[1] !== 0) {
      // @ts-ignore
      map.current.flyTo(coordinates, 13)
    }
  }, [coordinates])
  return (
      <Card>
        <Card>
          <S.Wrapper>

            <Card>
              <MapContainer
                  // note that MapContainer requires fixed width/heigh, otherwise you see nothing.
                  style={{width: '100%', height: '500px'}}
                  id="map"
                  zoom={3}
                  ref={map}
                  // @ts-ignore
                  center={coordinates}
                  // @ts-ignore
                  loadingControl={true}
                  separate={true}

              >
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                <MarkerClusterGroup chunkedLoading>
                  {geoPoints.map(({id, FacilityName, coordinates}, index) => {
                    const params = new URLSearchParams();
                    params.append('kind', '*:*:*:*');
                    params.append('query', `id:"${id}"`);
                    return (
                        <Marker key={index} icon={Pin} position={coordinates}>
                          <Popup>
                            Well Name: <strong> {FacilityName} </strong> <br/>
                            <Link to={`/search?${params.toString()}`}>
                              Show in Data console
                            </Link>
                          </Popup>
                        </Marker>
                    );
                  })}
                </MarkerClusterGroup>
              </MapContainer>
            </Card>
          </S.Wrapper>
        </Card>
      </Card>

  );
}
