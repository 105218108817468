const productId = process.env.REACT_APP_PRODUCT_ID as string;
const baseUrl = process.env.REACT_APP_BASE_API_URL_DISPLAY as string;

interface IProductLinks {
  eula: string;
  productOverview: string;
  productDocumentation: string;
  userGuide: string;
}

type IProductLink = Record<
  'eula' | 'productOverview' | 'productDocumentation' | 'userGuide',
  string
>;

const adoptCatLinks: IProductLink = {
  eula: 'https://www.47lining.com/paas/docs/adopt/adopt-docs-audience@latest/adopt-docs/commercial-terms/eula/',
  productOverview:
    'https://www.47lining.com/paas/products/products-audience/latest/adopt/enterprise-paas-adopt/',
  productDocumentation: 'https://www.47lining.com/paas/docs/adopt/adopt-docs-audience@latest/',
  userGuide:
    'https://www.47lining.com/paas/docs/adopt/adopt-docs-audience@latest/adopt-docs/user-guide/portal/',
};

const productLinks: Record<string, IProductLink> = {
  OSDUR2: {
    eula: 'https://www.47lining.com/paas/docs/preview/preview-docs-audience@latest/preview-docs/commercial-terms/eula/',
    productOverview:
      'https://www.47lining.com/paas/products/products-audience/latest/preview/enterprise-paas-preview/',
    productDocumentation:
      'https://www.47lining.com/paas/docs/preview/preview-docs-audience@latest/',
    userGuide:
      'https://www.47lining.com/paas/docs/preview/preview-docs-audience@latest/preview-docs/user-guide/portal/',
  },
  DEVACC: {
    eula: 'https://www.47lining.com/paas/docs/accelerator/accelerator-docs-audience@latest/accelerator-docs/commercial-terms/eula/',
    productOverview:
      'https://www.47lining.com/paas/products/products-audience/latest/accelerator/enterprise-paas-accelerator/',
    productDocumentation:
      'https://www.47lining.com/paas/docs/accelerator/accelerator-docs-audience@latest/',
    userGuide:
      'https://www.47lining.com/paas/docs/accelerator/accelerator-docs-audience@latest/accelerator-docs/user-guide/portal/',
  },
  ADOPTCAT: adoptCatLinks,
  MTAC: adoptCatLinks,
};

let links: IProductLinks = {
  eula: baseUrl || '#',
  productDocumentation: baseUrl || '#',
  productOverview: baseUrl || '#',
  userGuide: baseUrl || '#',
};

if (Object.keys(productLinks).includes(productId)) {
  links = productLinks[productId];
}

const footerLinks = [
  { text: 'Terms of Use', path: links.eula },
  {
    text: 'Privacy Policy',
    path: 'https://www.hitachivantara.com/en-us/company/legal.privacy.html',
  },
  { text: 'Legal', path: 'https://www.hitachivantara.com/en-us/company/legal.html' },
];

export { links, footerLinks };
