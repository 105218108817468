import { useState, ReactElement, useEffect } from 'react';
import { Auth } from '@aws-amplify/auth';
import { footerLinks, links } from 'configuration';
import { IPageInformation } from '../../pages/Pages.d';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CloseIcon from '@mui/icons-material/Close';
import CommonMenu from 'layout/components/TopBar/Components/CommonMenu/CommonMenu';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import EXPLORE_CURRENT_IMPLEMENTATION from 'pages/ExploreCurrentImplementation/ExploreCurrentImplementation';
import Footer from 'layout/components/Footer/Footer';
import HOME from 'pages/Home/Home';
import IN_PRODUCTION from 'pages/InProduction/InProduction';
import MANAGEMENT_CONSOLE from 'pages/DataConsole/DataConsole';
import SETTINGS_MODULE from 'pages/Settings/Settings';
import MEET_FUTURE_NEEDS from 'pages/MeetFutureNeeds/MeetFutureNeeds';
import MenuIcon from '@mui/icons-material/Menu';
import pages from '../../pages';
import SAMPLE_APP from 'pages/sample-app';
import SideBar from 'layout/components/SideBar/SideBar';
import SideBarItem from 'layout/components/SideBar/Components/SideBarItem/SideBarItem';
import TopBar from 'layout/components/TopBar/TopBar';
import useMediaQuery from '@mui/material/useMediaQuery';
import RedoIcon from '@mui/icons-material/Redo';
import { useNavigate } from 'react-router-dom';
import { dataConsoleLinks } from './dataMenu';
import AuthPage from 'pages/AuthPage/AuthPage';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, settingsActions, platformStateActions } from 'store';
import * as S from './Navigation.style';

function getChildPagePaths(childPages: IPageInformation[]) {
  return new Set(childPages.map((page) => page.path));
}

const childPages: Record<string, Set<string>> = {
  '/Home': getChildPagePaths(HOME),
  '/Settings': getChildPagePaths(SETTINGS_MODULE),
  '/ExploreCurrentImplementation': getChildPagePaths(EXPLORE_CURRENT_IMPLEMENTATION),
  '/MeetFutureNeeds': getChildPagePaths(MEET_FUTURE_NEEDS),
  '/InProduction': getChildPagePaths(IN_PRODUCTION),
  '/DataConsole': getChildPagePaths(MANAGEMENT_CONSOLE),
  '/OsduVisualizationApp': getChildPagePaths(SAMPLE_APP),
  '/MapViewer': new Set([]),
};

interface NavigationProps {
  children: ReactElement;
}

export default function Navigation({ children }: NavigationProps) {
  const { pathname } = useLocation();
  const [isMainMenu, setIsMainMenu] = useState(true);
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const username = user && user.username ? user.username : '';
  const [openSidebar, setOpenSidebar] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const { logout } = platformStateActions;

  const dispatch = useDispatch();
  const { setToastState } = settingsActions;
  const dataPartitionId = useSelector((state: RootState) => state.settings.dataPartitionId);
  const isMultiTenantHost = useSelector((state: RootState) => state.settings.isMultiTenantHost);

  const handleSignOut = () => {
    signOut();
    dispatch(logout());
  };

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });
  const toggleSideBar = () => setOpenSidebar((prev) => !prev);
  const handleSidebarClose = () => setOpenSidebar(false);

  const handleUserMenuChange = () => {
    console.log('handleUserMenuChange');
  };

  function isLinkActive(currentPath: string, linkPath: string) {
    return childPages[linkPath].has(currentPath);
  }

  const openInNewTab = (url: string) => {
    const win = window.open(url, '_blank');
    win?.focus();
  };

  const mainSidebarItems = pages
    .filter(({ addToSideMenu }) => addToSideMenu)
    .map(({ title, path, icon }, i) => (
      <SideBarItem
        key={i}
        name={title ? title : ''}
        icon={icon}
        onClick={() => {
          setOpenSidebar(false);
          navigate(path);
        }}
        isActive={isLinkActive(pathname, path)}
      />
    ));

  const dataConsoleItems = dataConsoleLinks.map(({ title, path, icon }, i) => (
    <SideBarItem
      key={i}
      name={title}
      icon={icon}
      onClick={() => {
        path && setOpenSidebar(false);
        !path && setIsMainMenu(true);
        path && navigate(path);
      }}
      isActive={pathname === path}
    />
  ));

  const sideMenuItems = isMainMenu
    ? pathname.includes('Load') ||
      pathname.includes('Search') ||
      pathname === '/Schema' ||
      pathname === '/LegalTags' ||
      pathname === '/Entitlement'
      ? [
          <SideBarItem
            key={'root'}
            name={'Data Console Subpages'}
            icon={<RedoIcon />}
            onClick={() => setIsMainMenu(false)}
            isActive={false}
          />,
          ...mainSidebarItems,
        ]
      : mainSidebarItems
    : dataConsoleItems;

  const mtUserMenuItems = [
    {
      name: `Partition ID: ${dataPartitionId}`,
      action: () => {
        navigator.clipboard.writeText(dataPartitionId);
        dispatch(
          setToastState({
            errorSeverity: 'success',
            message: 'Data partition ID copied to clipboard',
          })
        );
      },
      icon: <FileCopyIcon />,
    },
  ];

  const userMenuItems = [
    {
      name: 'Copy token',
      action: () => {
        Auth.currentSession().then((x) => {
          navigator.clipboard.writeText('Bearer ' + x.getAccessToken().getJwtToken());
          dispatch(
            setToastState({
              errorSeverity: 'success',
              message: 'Access token copied to clipboard',
            })
          );
        });
      },
      icon: <AssignmentOutlinedIcon />,
    },
    {
      name: 'Sign out',
      action: handleSignOut,
      icon: <ExitToAppOutlinedIcon />,
    },
  ];

  function UserMenu() {
    return (
      <CommonMenu
        icon={<S.AccountIcon />}
        title={username}
        handleMenuChange={handleUserMenuChange}
        menuOptions={isMultiTenantHost ? [...mtUserMenuItems, ...userMenuItems] : userMenuItems}
      />
    );
  }

  const helpMenu = (
    <CommonMenu
      icon={<S.HelpIcon />}
      title={'Help'}
      menuOptions={[
        {
          name: 'Product Overview',
          action: () => openInNewTab(links.productOverview),
        },
        {
          name: 'Product Documentation',
          action: () => openInNewTab(links.productDocumentation),
        },
      ]}
    />
  );

  useEffect(() => {
    window && window.scrollTo(0, 0);
    if (
      pathname.includes('Load') ||
      pathname.includes('Search') ||
      pathname === '/Schema' ||
      pathname === '/LegalTags' ||
      pathname === '/Entitlement'
    ) {
      setIsMainMenu(false);
    } else {
      setIsMainMenu(true);
    }
  }, [pathname]);

  return (
    <S.Wrapper>
      <TopBar
        menuIcon={openSidebar ? <CloseIcon /> : <MenuIcon />}
        toggleSideBar={toggleSideBar}
        helpMenu={helpMenu}
        userMenu={<UserMenu />}
      />
      {username && (
        <SideBar
          variant="persistent"
          open={isDesktop ? true : openSidebar}
          onClose={handleSidebarClose}
          userMenu={<UserMenu />}
          items={sideMenuItems}
        />
      )}
      <S.Container props={{ isLoggedIn: Boolean(user) }}>
        <S.Content>
          {children}
          <AuthPage />
        </S.Content>
        <Footer footerLinks={footerLinks} />
      </S.Container>
    </S.Wrapper>
  );
}
