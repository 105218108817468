import { ChangeEvent, LegacyRef, useRef, DragEvent } from 'react';
import * as S from './TextareaWithDrop.style';

interface ITextareaWithDrop {
  value: string;
  setValue: (value: string) => void;
  onFileIsInvalid?: () => void;
  isError: boolean;
  children: JSX.Element | JSX.Element[];
  label: string;
  required?: boolean;
}

const acceptedFiles = ['application/json', 'text/plain'];

export default function TextareaWithDrop({
  value,
  setValue,
  onFileIsInvalid,
  isError,
  children,
  label,
  required = false,
}: ITextareaWithDrop) {
  const manifestInputRef: LegacyRef<HTMLInputElement> = useRef(null);

  const handleReadFile = (file: File) => {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        setValue(String(reader.result));
      },
      false
    );
    reader.readAsText(file, 'utf-8');
  };

  const onDrop = (e: DragEvent) => {
    e.preventDefault();
    const file = e.dataTransfer.files.length > 0 && e.dataTransfer.files[0];
    if (!file || !acceptedFiles.includes(file.type)) {
      onFileIsInvalid && onFileIsInvalid();
      return;
    }
    handleReadFile(file);
  };

  const handleInputManifest = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    file && handleReadFile(file);
  };

  const selectManifestFormDisc = () => manifestInputRef.current && manifestInputRef.current.click();

  return (
    <>
      <h4>{label}</h4>
      <S.Row>
        {children}
        <S.StyledButton onClick={selectManifestFormDisc}>Select from disc</S.StyledButton>
      </S.Row>
      <S.HiddenInput
        type="file"
        accept={acceptedFiles.join(', ')}
        onChange={handleInputManifest}
        ref={manifestInputRef}
        aria-hidden
      />
      <S.Textarea
        required={required}
        props={{ isError }}
        onDrop={onDrop}
        value={value}
        onChange={(e: ChangeEvent<{ value: string }>) => setValue(e.target.value)}
        placeholder={'Pass or drop JSON file'}
      />
    </>
  );
}
