import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '@aws-amplify/api';
import {
  ChangePlatformState,
  IPlatformStatusResponse,
  getPlatformStatus,
} from 'services/platformService';
import { RootState } from 'store';
import { PLATFORM_STATE, IServiceData } from 'types/Platform';
import { IGroup } from 'types/User';
import { services } from 'services/services';

const GROUPS_WITH_PERMISSIONS: Array<string> = ['service.entitlements.admin'];

const GROUPS_WITH_PERMISSIONS_MT: Array<string> = [
  'service.entitlements.admin',
  'service.entitlements.user',
];

export interface PlatformState {
  state: PLATFORM_STATE;
  serviceStatuses: Array<IServiceData>;
  tooltipMessage: string;
  platformStateTermination?: string;
  isEntitlementsAdminUser: boolean;
  isCheckingEntitlementsPrivileges: boolean;
  shouldRefreshPlatformState: boolean;
}

const initialState: PlatformState = {
  state: PLATFORM_STATE.CHECKING,
  serviceStatuses: services,
  tooltipMessage: '',

  isEntitlementsAdminUser: false,
  isCheckingEntitlementsPrivileges: true,
  shouldRefreshPlatformState: false,
};

interface IGroupsRequest {
  groups: Array<IGroup>;
  desId: string;
  memberEmail: string;
}

const getPlatformState = createAsyncThunk('platformState/getPlatformState', async () => {
  return await getPlatformStatus();
});

const changePlatformState = createAsyncThunk(
  'platformState/changePlatformState',
  async (
    {
      requestedState,
      requestedTime,
    }: {
      requestedState: 'On' | 'Off';
      requestedTime?: number;
    },
    { rejectWithValue }
  ) => {
    try {
      await ChangePlatformState(requestedState, requestedTime);
      return PLATFORM_STATE.PENDING;
    } catch (error) {
      rejectWithValue(PLATFORM_STATE.ERROR);
    }
  }
);

const checkIsEntitlementsAdmin = createAsyncThunk(
  'platformState/checkEntitlementsPrivileges',
  async (payload, { getState, rejectWithValue }) => {
    const store = getState() as RootState;
    const {
      settings: { dataPartitionId, isMultiTenantHost },
      platformState: { state },
    } = store;

    if (state === PLATFORM_STATE.OFF || state === PLATFORM_STATE.PENDING) {
      return false;
    }

    let isAdminTemp = false;
    try {
      const { groups }: IGroupsRequest = await API.get('api', `/api/entitlements/v2/groups`, {
        headers: {
          'data-partition-id': dataPartitionId,
        },
      });
      const groupNames = groups.map(({ name }) => name);
      const groupsToCheck = isMultiTenantHost
        ? GROUPS_WITH_PERMISSIONS_MT
        : GROUPS_WITH_PERMISSIONS;
      for (const adminGroup of groupsToCheck) {
        if (groupNames.includes(adminGroup)) {
          isAdminTemp = true;
          break;
        }
      }
    } catch (error) {
      const { response } = (error as any) || { response: {} };
      if (response?.data?.code === 401) {
        return false;
      } else {
        return rejectWithValue(false);
      }
    }
    return isAdminTemp;
  }
);

export const platformStateSlice = createSlice({
  name: 'platformState',
  initialState,
  reducers: {
    logout: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getPlatformState.pending, (state) => {
      return {
        ...state,
        state: PLATFORM_STATE.CHECKING
      }
    });
    builder.addCase(
      getPlatformState.fulfilled,
      (
        state,
        {
          payload: { PlatformState, tooltipMessage, serviceStatuses, Timestamp },
        }: PayloadAction<IPlatformStatusResponse>
      ) => {
        return {
          ...state,
          state: PlatformState,
          tooltipMessage: tooltipMessage,
          serviceStatuses: [...serviceStatuses],
          platformStateTermination: Timestamp,
          shouldRefreshPlatformState: false
        }
      }
    );
    builder.addCase(
      checkIsEntitlementsAdmin.fulfilled,
      (state, { payload }: PayloadAction<boolean>) => {
        return {
          ...state,
          isCheckingEntitlementsPrivileges: false,
          isEntitlementsAdminUser: payload
        }
      }
    );
    builder.addCase(checkIsEntitlementsAdmin.rejected, (state) => {
      return {
        ...state,
        isCheckingEntitlementsPrivileges: true,
        isEntitlementsAdminUser: false
      }
    });
    builder.addCase(checkIsEntitlementsAdmin.pending, (state) => {
      return {
        ...state,
        isCheckingEntitlementsPrivileges: true
      }
    });
    builder.addCase(changePlatformState.pending, (state) => {
      return {
        ...state,
        state:  PLATFORM_STATE.CHECKING
      }
    });
    builder.addCase(changePlatformState.rejected, (state) => {
      return {
        ...state,
        isCheckingEntitlementsPrivileges: true,
        state: PLATFORM_STATE.ERROR,
        serviceStatuses: services
      }
    });
    builder.addCase(changePlatformState.fulfilled, (state) => {
      return {
        ...state,
        isCheckingEntitlementsPrivileges: true,
        state: PLATFORM_STATE.PENDING,
        serviceStatuses: services
      }
    });
  },
});

export const actions = {
  ...platformStateSlice.actions,
  getPlatformState,
  checkIsEntitlementsAdmin,
  changePlatformState,
};

export default platformStateSlice.reducer;
