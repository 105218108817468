import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './amplifyTheme.css';
import { useLocation } from 'react-router-dom';
import * as S from './AuthPage.style';

export default function AuthPage() {
  const { pathname } = useLocation();
  const components = {
    SignIn: {
      Header: () => <S.Header>Sign in to the OSDU™ Data Platform</S.Header>,
    },
  };

  return (
    <S.AuthWrapper>
      <Authenticator
        loginMechanisms={['email']}
        hideSignUp
        components={components}
        initialState={pathname === '/r' ? 'resetPassword' : 'signIn'}
      >
        {() => <main />}
      </Authenticator>
    </S.AuthWrapper>
  );
}
