import HOME from './Home/Home';
import MANAGEMENT_CONSOLE from './DataConsole/DataConsole';
import EXPLORE_CURRENT_IMPLEMENTATION from './ExploreCurrentImplementation/ExploreCurrentImplementation';
import IN_PRODUCTION from './InProduction/InProduction';
import MEET_FUTURE_NEEDS from './MeetFutureNeeds/MeetFutureNeeds';
import SAMPLE_APP from './sample-app';
import SETTINGS_MODULE from './Settings/Settings';
import { IPageInformation } from './Pages.d';
import MapViewerPage from './MapViewerPage';

const pages: IPageInformation[] = [
  ...HOME,
  ...SETTINGS_MODULE,
  ...MANAGEMENT_CONSOLE,
  ...SAMPLE_APP,
  ...EXPLORE_CURRENT_IMPLEMENTATION,
  ...IN_PRODUCTION,
  ...MEET_FUTURE_NEEDS,
  MapViewerPage,
];

export default pages;
