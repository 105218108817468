import LeafletMapContainer from './LeafletMapContainer'
import { mapViewerStateActions, RootState } from 'store';
import { useEffect, useRef, useState } from 'react';
import "leaflet-loading"
import { useDispatch, useSelector } from 'react-redux';
import * as S from './MapViewerPage.style';
import { searchGeoPoints } from "../../Utils/mapViewer";
import Card from '@mui/material/Card';
import MapController from "./MapController";
import { setWells } from "../../store/mapViewerStateSlice";

export default function MapViewerPage() {
  const dataPartitionId = useSelector((state: RootState) => state.settings.dataPartitionId);
  const coordinates = useSelector((state: RootState) => state.mapViewerState.coordinates);
  const geoPoints = useSelector((state: RootState) => state.mapViewerState.wells);
  const map = useRef(null)
  const [error, setError] = useState('');
  const { clearWells } = mapViewerStateActions;
  const dispatcher = useDispatch()
  const initialKind = '*:*:*:*';
  const [kindValue, setKindValue] = useState(initialKind);
  const [queryValue, setQueryValue] = useState('');
  const handleSearch = () => {
    if (geoPoints.length != 0) {
      dispatcher(clearWells())
    }
    fetchData()
  };
  const fetchData = async () => {
    let cursor = null
    let response = await searchGeoPoints(dataPartitionId, cursor, kindValue, queryValue);
    //todo it will not save geopoints if query will return less then 1000 results
    while (response.cursor !== null) {
      cursor = response.cursor;
      // we are storing  wells in redux so the map would update with each new batch
      dispatcher(setWells(response.points))
      response = await searchGeoPoints(dataPartitionId, cursor, kindValue, queryValue);
    }

  };

  useEffect(() => {
    if (map.current !== null && coordinates[0] !== 0 && coordinates[1] !== 0) {
      // @ts-ignore
      map.current.flyTo(coordinates, 13)
    }
  }, [coordinates])
  return (
      <Card>
        <Card>
          <Card>
            <MapController
                kindValue={kindValue}
                queryValue={queryValue}
                setKindValue={setKindValue}
                setQueryValue={setQueryValue}
                handleSearch={handleSearch}
            />
          </Card>
          {error && <S.ErrorMessage>{error}</S.ErrorMessage>}
        </Card>
        {!error && (
            <Card>
              <LeafletMapContainer
                  geoPoints={geoPoints}
                  coordinates={coordinates}

              />
            </Card>
        )}

      </Card>

  );
}
